<template>
  <div>
    <c-search-box :isShowSearch="false" @enter="getList">
      <template slot="search">
        <div class="col-6">
          <c-plant type="search" name="plantCd" v-model="popupParam.plantCd" @datachange="getList"/>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="1M"
            label="변경 작업 기간"
            name="mocPeriod"
            v-model="searchParam.mocPeriod"
          />
        </div> -->
        <div class="col-6">
          <!-- 발의부서 -->
          <c-dept type="search" label="발의부서" name="initiativeDeptCd" @datachange="getList" v-model="searchParam.initiativeDeptCd" />
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="stepItems"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="mocStepCd"
            label="진행단계"
            v-model="searchParam.mocStepCd"
          ></c-select>
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-select
            codeGroupCd="MOC_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="mocTypeCd"
            label="변경타입"
            v-model="searchParam.mocTypeCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      :columns="grid.columns"
      :selection="popupParam.type"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="sopMocId"
      :filtering="true"
      :columnSetting="false"
      :isFullScreen="false"
      :isTitle="false"
    >
    </c-table>
    <div class="space-bottom-button"></div>
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="선택" icon="check" color="teal-5" @btnClicked="select" />
      </q-btn-group>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'moc-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          mocRelatedTaskCd: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        mocPeriod: [],
        initiativeDeptCd: null,
        mocStepCd: '99',
        mocTypeCd: null,
        mocRelatedTaskCd: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'mocStepName',
            field: 'mocStepName',
            label: '진행단계',
            align: 'center',
            sortable: true
          },
          {
            name: 'mocTitle',
            field: 'mocTitle',
            label: '제목',
            align: 'left',
            sortable: true,
          },
          {
            name: 'mocTypeName',
            field: 'mocTypeName',
            label: '변경구분',
            align: 'center',
            sortable: true
          },
          // {
          //   name: 'mocNo',
          //   field: 'mocNo',
          //   label: '관리번호',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          // {
          //   name: 'mocDates',
          //   field: 'mocDates',
          //   label: '변경 작업 기간',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: true
          // },
          // {
          //   name: 'initiativeDeptName',
          //   field: 'initiativeDeptName',
          //   label: '발의부서',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          // {
          //   name: 'initiativeUserName',
          //   field: 'initiativeUserName',
          //   label: '발의자',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
        ],
        height: '400px',
        data: [],
      },
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        width: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.moc.change.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.searchParam.plantCd = this.$_.clone(this.popupParam.plantCd)
      this.searchParam.mocRelatedTaskCd = this.$_.clone(this.popupParam.mocRelatedTaskCd)
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: 'MOC번호를 선택하세요.', // MOC번호를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '변경관리 상세'; // 작업허가서 상세
      this.popupOptions.param = {
        sopMocId: row.sopMocId,
        mocTypeCd: row.mocTypeCd,
        mocStepCd: row.mocStepCd,
      };
      this.popupOptions.target = () => import(`${'./mocDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
